import Swiper from "swiper";
import {Navigation, Pagination} from 'swiper/modules';
import {Fancybox} from "@fancyapps/ui";

function initializeImageSlider() {
    const swiperInstances = [];

    document.querySelectorAll('.acf-block-image-slider .swiper').forEach((container, index) => {
        const nextButton = container.querySelector('.swiper-button-next');
        const prevButton = container.querySelector('.swiper-button-prev');
        const pagination = container.querySelector('.swiper-pagination');
        const swiperParams = {
            modules: [Navigation, Pagination],
            loop: true,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
            pagination: {
                el: pagination,
                clickable: true
            },
            slidesPerView: 1,
            spaceBetween: 20,
        };
        const swiper = new Swiper(container, swiperParams);
        const id = container.getAttribute('data-id');
        swiperInstances.push({id, swiper});
    });

    Fancybox.bind('[data-fancybox]', {
        Thumbs: {
            type: "modern",
        },
        Toolbar: {
            display: {
                left: [],
                middle: [],
                right: ["close"],
            },
        },
        on: {
            "Carousel.change": (fancybox) => {
                const slide = fancybox.getSlide();
                const id = slide?.fancybox ?? null
                if (id) {
                    const swiperInstance = swiperInstances.find(instance => instance.id === id);
                    if (swiperInstance) swiperInstance.swiper.slideTo(slide.index);
                }
            },
        },
    });
}


export function imageSlider() {
    // Для редактора
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=image-slider', initializeImageSlider);
    }

    // Для фронтенда
    initializeImageSlider();
}
